.custom-toast-success {
    border: 1px solid #9FD89F;
    background-color: #F1FAF1 !important;
    border-radius: 4px;
    color: #252525 !important;

}

.custom-toast-error {
    border: 1px solid #EEACB2;
    background-color: #FDF3F4 !important;
    border-radius: 4px;
    color: #252525 !important;
}

.custom-toast-warn {
    border: 1px solid #FDCFB4;
    background-color: #FFF9F5 !important;
    border-radius: 4px;
    color: #252525 !important;
}

.custom-toast-info {
    border: 1px solid #24ade6;
    background-color: #d3f2ff !important;
    border-radius: 4px;
    color: #252525 !important;
}

.Toastify__close-button {
    color: #252525 !important;
    top: unset !important;
}

.Toastify__toast {
    width: 70vh !important;
}