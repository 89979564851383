@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-45Regular.woff2') format('woff2'),
        url('CoreSansE-45Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-45RegularIt.woff2') format('woff2'),
        url('CoreSansE-45RegularIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-55Medium.woff2') format('woff2'),
        url('CoreSansE-55Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-55MediumIt.woff2') format('woff2'),
    url('CoreSansE-55MediumIt.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-65Bold.woff2') format('woff2'),
        url('CoreSansE-65Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans E';
    src: url('CoreSansE-65BoldIt.woff2') format('woff2'),
    url('CoreSansE-65BoldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
