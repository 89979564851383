html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  /*background: url(./img/spacer.png) 0 0;*/
  /*background-size: 100%;*/
  /* background-color: #f2f3f3; */
  margin: 0px;
  -webkit-user-select: none;
  font-family: Segoe UI;
}

#root {
  height: 100%;
}

#root #login-container {
  height: 100%;
  width: 100%;
  background: url("./img/bg.jpg") center bottom no-repeat;
  background-size: cover;
}

.b-login-form__header {
  margin: 0 0 30px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li, a, p, span, h1, h2, h3, h4, input, label, div {
  /* font-weight: 300; */
  font-weight: 400;
}

@font-face {
  font-family: FuturaPT;
  font-weight: 300;
  src: url("./fonts/book/FuturaPTBook.eot");
  src: url("./fonts/book/FuturaPTBook.eot");
  src: url("./fonts/book/FuturaPTBook.svg");
  src: url("./fonts/book/FuturaPTBook.woff");
  src: url("./fonts/book/FuturaPTBook.ttf")
}

@font-face {
  font-family: FuturaPT;
  font-weight: 500;
  src: url("./fonts/medium/FuturaPTMedium.eot");
  src: url("./fonts/medium/FuturaPTMedium.eot");
  src: url("./fonts/medium/FuturaPTMedium.svg");
  src: url("./fonts/medium/FuturaPTMedium.woff");
  src: url("./fonts/medium/FuturaPTMedium.ttf")
}

@font-face {
  font-family: FuturaPT;
  font-weight: 700;
  src: url("./fonts/demi/FuturaPTDemi.eot");
  src: url("./fonts/demi/FuturaPTDemi.eot");
  src: url("./fonts/demi/FuturaPTDemi.svg");
  src: url("./fonts/demi/FuturaPTDemi.woff");
  src: url("./fonts/demi/FuturaPTDemi.ttf")
}

.logo {
  background: url(./img/logo.svg) 0 center no-repeat;
  background-size: 100%;
  width: 1.6rem;
  height: 100%;
  margin-right: 5px;
  color: #242424 ;
}

@font-face {font-family: "Amazon Ember Regular";
  src: url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.eot"); /* IE9*/
  src: url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.woff2") format("woff2"), /* chrome、firefox */
  url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.woff") format("woff"), /* chrome、firefox */
  url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./fonts/aws/157c6cc36dd65b1b2adc9e7f3329c761.svg#Amazon Ember Regular") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: 'Segoe UI';
  /* font-weight: 100; */
  src: url("./fonts/segoe-ui/SegoeUI.ttf"),
  /* src: url("../fonts/segoe-ui/SegoeUIBold.ttf"),
  src: url("../fonts/segoe-ui/SegoeUIBoldItalic.ttf"),
  src: url("../fonts/segoe-ui/SegoeUIItalic.ttf"), */
} 

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  overflow: auto;
}

#atlwdg-trigger {
  display: none;
}

.historyTable > div {
  margin-bottom: unset !important;
}

.react-grid-layout .react-grid-item {
  position: relative !important;

  height: unset !important;
  width: 100% !important;
  transform: translate(10px, 10px) !important;
}
/* .dropdown{
  width: 50%;
  padding: 0;
  margin-top: 20px;
} */
.number-inp{
  position: relative;
  line-height: 1;
  /* width: 25%; */
  height: 20px;
  /* display: inline-flex; */
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  background: #fff;
  cursor: auto;
  padding: 6px 20px 6px 12px;
}
.number-inp input{
  display: inline-flex;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  /* color: #636363; */
}
.number-inp .Alterdropup{
  top: -10px;
  right: 4px;
  cursor: pointer;
  position: absolute;
  font-size: 12px;
}

.number-inp .Alterdropup:hover {
  background-color: transparent; /* Adjust as needed */
}

.number-inp .Alterdropup:focus{
  color: #0F6CBD;
}
.number-inp .Alterdropdown{
  top: 5px;
  right: 4px;
  cursor: pointer;
  position: absolute;
}
.number-inp .Alterdropdown:hover {
  background-color: transparent; /* Adjust as needed */
}
.number-inp .Alterdropdown:focus{
  color: #0F6CBD;
}
.number-inp .arrowUpIcon .arrowDownIcon {
  font-size: 18px;
  display: block;
  position: absolute;
  right: 3px;
  cursor: pointer;
}

.number-inp .up{
  padding: 0;
  margin-top: 3.5px;
}
.number-inp .down{
  padding: 0;
  margin-top: 3px;
}

.time{
  background-color: #2c2c2c;
    color: #bfbfbf;
    padding: 10px 20px;
    border: 1px solid #3a3a3a;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}
.time:hover {
  background-color: #3a3a3a;
  color: white;
}
.timeSettingName {
  font-size: 13px;
  border-right: solid 2px grey;
  padding: 0.2rem;
  cursor: pointer;
}
.timeRangeSetting {
  font-size: 13px;
  padding: 0.2rem;
  cursor: pointer;
}
.settings-footer{
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}
.MuiInputBase-input .dateTimePicker{
  font-size: 12px !important;
}
textarea.text-input {
  margin-left: 30px;
  width: 80%;
}
.MuiCheckbox-colorSecondary{
  border-color: #0F6CBD !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
  color: #0F6CBD !important;
}
.MuiRadio-colorSecondary.Mui-checked{
  color: #0F6CBD !important;
}
.settingHeader {
  display: flex;
  margin-bottom: 4px;
  margin-top: 7px;
  justify-content: center;
}
.highcharts-reset-zoom {
  display: none !important;
}

#startPage {
  background-image: url("./img/dashboard_img.jpg");
  background-size: cover;
}
/* .SplitPane:first-child div {
  overflow: auto;
} */

.App {
  /* font-family: sans-serif; */
  font-family: Segoe UI;
  text-align: center;
}

.SplitPane .Pane {
  overflow: auto;
}
.SplitPane .Pane1 {
  align-items: center;
}
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.MuiTableBody-root .MuiTableRow-root:hover {
  background-color: #0F6CBD !important;
}
.MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root {
  color: white !important;
}

.MuiDialog-container .MuiPaper-rounded{
  border-radius: 8px !important;
}

.MuiPaper-elevation24 {
  box-shadow: 0 5px 15px rgba(0,0,0,.5)  !important;
}

.dropDown-removePadding .MuiInputBase-root .MuiInputBase-input {
  padding: 0;
}

.react-lazylog-searchbar {
  width: 100%;
}