.notification-dialog {
  position: absolute;
  top: 55px;
  right: 5px;
  border: 1px solid var(--primary-highlight-color);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 450px;
  z-index: 1000;
  /* overflow-y: auto; */
  /* max-height: 460px; */
  overflow-y: auto;
}

.notification-dialog-mobile {
  position: absolute;
  top: 89px;
  right: 15px;
  /* left: 3px; */
  border: 1px solid var(--primary-highlight-color);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 360px;
  z-index: 1000;
  /* overflow-y: auto; */
  /* max-height: 460px; */
  overflow-y: auto;
}

.notification-dialog-mobile::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.notification-dialog-mobile {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notifications {
  padding: 10px;
}

.notification {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification:last-child {
  border-bottom: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.notification-scroll::-webkit-scrollbar {
  width: 7px;
}

.notification-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.notification-scroll::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

.notification-scroll::-webkit-scrollbar-thumb:hover {
  background: #0F6CBD;
}

.notification-item:hover {
  background: #e3f2fd; 
  box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.15);
}
/* Second Notification ui css */
/* @keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
} */
